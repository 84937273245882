import { FORM_DATA_RANDOM, FormDataType } from "../constant";
import { FormAnswers } from "../container/FormsIframe";

export type Results = Record<keyof FormDataType, number>;

//TODO zkontrolovat
const value = {
  a: 4,
  b: 2,
  c: -1,
  d: -2,
  e: -4,
};

export const getResult = (formValues: FormAnswers): Results => {
  const formValuesToUse = formValues.flat(1);
  const maxPoints: Record<keyof FormDataType, number> = {
    marketing: 0,
    marketingSales: 0,
    preference: 0,
    publicRelations: 0,
    realization: 0,
    sales: 0,
    vision: 0,
  };
  const minPoints: Record<keyof FormDataType, number> = { ...maxPoints };
  const points: Record<keyof FormDataType, number> = { ...maxPoints };
  if (formValuesToUse.length !== FORM_DATA_RANDOM.flat(1).length) {
    console.error("Number of ask and number of answer is not same!");
  }
  FORM_DATA_RANDOM.flat(1).forEach((item, index) => {
    maxPoints[item.type] = maxPoints?.[item.type] + value.a;
    minPoints[item.type] = minPoints?.[item.type] + value.e;
    points[item.type] =
      points?.[item.type] + value?.[formValuesToUse?.[index]] || 0;
  });
  return Object.keys(points).reduce((all, key) => {
    const maxPoint = maxPoints[key as keyof FormDataType];
    const minPoint = minPoints[key as keyof FormDataType];
    const totalPoints = points[key as keyof FormDataType];
    return {
      ...all,
      [key]: (totalPoints - minPoint) / (maxPoint - minPoint),
    };
  }, {}) as Results;
};
