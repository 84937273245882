import styled from "styled-components";
import { systemMargin, SystemMarginType } from "../../theme/Spacing";

export interface FlexProps {
  flexDirection?: "row" | "column" | "column-reverse";
  flexWrap?: "wrap" | "wrap-reverse";
  fullWidth?: boolean;
  justifyContent?: string;
  alignItems?: string;
  position?: string;
  width?: number;
  height?: number | string;
  marginTop?: SystemMarginType;
  marginBottom?: SystemMarginType;
  marginLeft?: SystemMarginType;
  marginRight?: SystemMarginType;
  paddingLeft?: SystemMarginType;
  paddingRight?: SystemMarginType;
}

export const Flex = styled.div<FlexProps>`
  display: flex;
  ${({ flexDirection }) => flexDirection && `flex-direction: ${flexDirection};`}
  ${({ fullWidth }) => fullWidth && `width: 100%;`}
  ${({ width }) => width && `width: ${width}px;`}
  ${({ height }) =>
    height && `height: ${typeof height === "string" ? height : height + "px"};`}
  ${({ position }) => position && `position: ${position};`}
  ${({ flexWrap }) => flexWrap && `flex-wrap: ${flexWrap};`}
  ${({ justifyContent }) =>
    justifyContent && `justify-content: ${justifyContent};`}
  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
  ${({ marginTop, theme }) =>
    marginTop &&
    `margin-top: ${
      marginTop === "auto"
        ? "auto"
        : theme.spacing(systemMargin[marginTop]) + "px"
    };`}
  ${({ marginBottom, theme }) =>
    marginBottom &&
    `margin-bottom: ${
      marginBottom === "auto"
        ? "auto"
        : theme.spacing(systemMargin[marginBottom]) + "px"
    };`}
  ${({ marginLeft, theme }) =>
    marginLeft &&
    `margin-left: ${
      marginLeft === "auto"
        ? "auto"
        : theme.spacing(systemMargin[marginLeft]) + "px"
    };`}
  ${({ marginRight, theme }) =>
    marginRight &&
    `margin-right: ${
      marginRight === "auto"
        ? "auto"
        : theme.spacing(systemMargin[marginRight]) + "px"
    };`}


  ${({ paddingLeft, theme }) =>
    paddingLeft &&
    `padding-left: ${
      paddingLeft === "auto"
        ? "auto"
        : theme.spacing(systemMargin[paddingLeft]) + "px"
    };`}
  ${({ paddingRight, theme }) =>
    paddingRight &&
    `padding-right: ${
      paddingRight === "auto"
        ? "auto"
        : theme.spacing(systemMargin[paddingRight]) + "px"
    };`}
`;
