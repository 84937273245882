import * as React from "react";
import { FC } from "react";
import { Grid, TextField } from "@material-ui/core";
import { Flex } from "../../../../core/components/layout/Flex";
import styled from "styled-components/macro";
import { H3, H4 } from "../../../../core/components/typography";
import { SubmittedStatus } from "../../container/FormsIframe";
import { isValidEmail } from "../../../../core/utils/isValidEmail";

export interface ContactInfo {
  email: string;
  phone: string;
  company: string;
  firstName: string;
  lastName: string;
}

interface IframeContactInfoProps {
  contact: ContactInfo;
  onChangeContact: (name: string) => (value: string) => void;
  setSubmitted: (value: SubmittedStatus) => void;
  submitted: SubmittedStatus;
}

const InputContainer = styled("div")`
  padding-left: 200px;
  padding-right: 200px;
  margin-bottom: 30px;
  margin-top: 15px;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding-left: 50px;
    padding-right: 50px;
  }
`;

export const IframeContactInfo: FC<IframeContactInfoProps> = ({
  contact,
  onChangeContact,
}) => {
  const emailIsValid = isValidEmail(contact.email);
  return (
    <Flex
      justifyContent={"center"}
      fullWidth={true}
      marginTop={"xxl"}
      flexDirection={"column"}
      marginBottom={"xxl"}
    >
      <Flex fullWidth={true} marginTop={"large"} justifyContent={"center"}>
        <H3 align={"center"}>Skvěle dotazník vyplněn!</H3>
      </Flex>
      <Flex fullWidth={true} justifyContent={"center"} marginBottom={"xxl"}>
        <H4 align={"center"}>Vložte prosím svůj email a telefon, abychom vám mohli doručit vaše výsledky a případně vás podpořit.</H4>
      </Flex>
      <InputContainer>
        <Grid container={true} spacing={4}>
          <Grid item={true} md={12} xs={12}>
            <TextField
              fullWidth={true}
              value={contact.company}
              label="Název firmy"
              onChange={(e) => onChangeContact("company")(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item={true} xs={12} sm={6}>
            <TextField
              fullWidth={true}
              required
              value={contact.firstName}
              label="Jméno"
              onChange={(e) => onChangeContact("firstName")(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item={true} sm={6} xs={12}>
            <TextField
              fullWidth={true}
              required
              value={contact.lastName}
              label="Příjmení"
              onChange={(e) => onChangeContact("lastName")(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item={true} md={12} xs={12}>
            <TextField
              fullWidth={true}
              required
              value={contact.email}
              label="Email"
              onChange={(e) => onChangeContact("email")(e.target.value)}
              variant="outlined"
              error={!!(!emailIsValid && contact.email)}
            />
          </Grid>
          <Grid item={true} md={12} xs={12}>
            <TextField
              fullWidth={true}
              required
              label="Telefon"
              value={contact.phone}
              onChange={(e) => onChangeContact("phone")(e.target.value)}
              variant="outlined"
              inputProps={{
                inputMode: "tel",
              }}
            />
          </Grid>
        </Grid>
      </InputContainer>
    </Flex>
  );
};
