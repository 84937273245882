import { createBrowserHistory } from "history";
import { HistoryLocation } from "./type";

const history = createBrowserHistory();

export const useHistoryLocation = <T,>() => {
  return locationToHistoryLocation<T>();
};

const locationToHistoryLocation = <T,>(): HistoryLocation<T> => {
  const search = history.location.search.substring(1);

  return {
    pathname: history.location.pathname,
    hash: history.location.hash,
    search: search
      ? JSON.parse(
          '{"' +
            decodeURI(search)
              .replace(/"/g, '\\"')
              .replace(/&/g, '","')
              .replace(/=/g, '":"') +
            '"}'
        )
      : {},
  };
};

const historyLocationToLocation = <T,>(
  location: HistoryLocation<T>
): string => {
  const search = location.search || {};
  const searchKeys = Object.keys(search);
  return (
    location.pathname +
    (searchKeys.length !== 0
      ? "?" +
        searchKeys.reduce((result, key, index) => {
          const keyValue = (search as any)[key];
          return result + (index !== 0 ? "&" : "") + key + ("=" + keyValue);
        }, "")
      : "") +
    (location.hash
      ? location.hash.startsWith("#")
        ? location.hash
        : "#" + location.hash
      : "")
  );
};

type NewUrlArguments<T = Record<string, string>> =
  | string
  | HistoryLocation<T>
  | ((historyLocation: HistoryLocation<any>) => HistoryLocation<T>);

export const historyPush = (arg: NewUrlArguments) => {
  if (typeof arg === "string") {
    history.push(arg);
  } else if (typeof arg === "function") {
    history.push(historyLocationToLocation(arg(locationToHistoryLocation())));
  } else {
    history.push(historyLocationToLocation(arg));
  }
};

export const historyReplace = <T,>(arg: NewUrlArguments<T>) => {
  if (typeof arg === "string") {
    history.replace(arg);
  } else if (typeof arg === "function") {
    history.replace(
      historyLocationToLocation(arg(locationToHistoryLocation()))
    );
  } else {
    history.replace(historyLocationToLocation(arg));
  }
};

export const historyOnlyForRouter = history;
