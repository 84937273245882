import React, { FC } from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { FORM_DATA_RANDOM } from "../../constant";
import { CurrentPage, FormAnswers } from "../../container/FormsIframe";

interface Props {
  currentPage: CurrentPage;
  formValues: FormAnswers;
}

const Root = styled.div`
  padding: ${(props) => props.theme.spacing(1, 2)};
  border-radius: ${(props) => props.theme.spacing(1)}px;
  background-color: ${(props) => props.theme.palette.secondary.main};
  color: ${(props) => props.theme.palette.secondary.contrastText};
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: ${(props) => props.theme.spacing(4)}px
    ${(props) => props.theme.spacing(4)}px
    ${(props) => props.theme.spacing(4)}px auto;
  z-index: 100;
`;

const useFormFilledPercent = ({ formValues }: { formValues: FormAnswers }) =>
  formValues.flat(1).length / FORM_DATA_RANDOM.flat(1).length;

const FormPercentages: FC<Props> = ({ formValues }) => {
  const filled = useFormFilledPercent({ formValues });
  return (
    <Root>
      <div>
        <Typography variant="h4">
          <strong>Vyplněno: {Math.round(filled * 1000) / 10} %</strong>
        </Typography>
      </div>
    </Root>
  );
};

export default FormPercentages;
