import { ReduxAction } from "../../type";
import {
  DELETE_ALL_ITEMS,
  PUSH_ITEM_OR_UPDATE,
  RESET,
  SET,
  SET_ITEM_PROPERTY,
} from "../../constants";

type ReduxActionOperationArray =
  | typeof SET
  | typeof DELETE_ALL_ITEMS
  | typeof PUSH_ITEM_OR_UPDATE
  | typeof SET_ITEM_PROPERTY
  | typeof RESET;

export interface ReduxActionArrayBasic extends ReduxAction {
  operation: typeof SET | typeof RESET;
}

export const actionReducerArraySet = (
  type: string,
  payload: any
): ReduxActionArrayBasic => ({
  type,
  operation: SET,
  payload,
});

export interface ReduxActionArrayDeleteItem extends ReduxAction {
  operation: typeof DELETE_ALL_ITEMS;
  propertyId?: string;
  payload: any;
}

export const actionReducerArrayDelete = (
  type: string,
  payload: any,
  propertyId?: string
): ReduxActionArrayDeleteItem => ({
  type,
  operation: DELETE_ALL_ITEMS,
  propertyId,
  payload,
});

export interface ReduxActionArrayPush extends ReduxAction {
  operation: typeof PUSH_ITEM_OR_UPDATE;
  propertyId?: string;
  payload: any;
}

export const actionReducerArrayPush = (
  type: string,
  payload: any,
  propertyId?: string
): ReduxActionArrayPush => ({
  type,
  operation: PUSH_ITEM_OR_UPDATE,
  propertyId,
  payload,
});

export interface ReduxActionArraySetItemProperty extends ReduxAction {
  operation: typeof SET_ITEM_PROPERTY;
  propertyName: string;
  propertyId: string;
  idToUpdate: string | number;
  payload: any;
}

export const actionReducerArraySetItemProperty = (
  type: string,
  idToUpdate: string | number,
  payload: any,
  propertyName: string,
  propertyId: string = "id"
): ReduxActionArraySetItemProperty => ({
  type,
  operation: SET_ITEM_PROPERTY,
  propertyName,
  idToUpdate,
  propertyId,
  payload,
});

export type ReduxActionArray =
  | ReduxActionArrayBasic
  | ReduxActionArrayPush
  | ReduxActionArraySetItemProperty
  | ReduxActionArrayDeleteItem;

export const createReducerArray = <T>(
  actionType: string,
  defaultState: T[] = []
) => (state = defaultState, action: ReduxActionArray): T[] => {
  if (action.type === actionType) {
    const actions: Record<ReduxActionOperationArray, () => T[]> = {
      [SET]: () => {
        if (action.payload === undefined) {
          throw new Error(
            `Can not set undefined to reducer. Reducer: ${actionType}, operation: ${SET}`
          );
        }
        return action.payload;
      },
      [PUSH_ITEM_OR_UPDATE]: () => {
        const propertyId = "propertyId" in action ? action.propertyId : "";
        if (propertyId) {
          //add or update by key
          if (
            state.some(
              (item: any) => item?.[propertyId] === action.payload?.[propertyId]
            )
          ) {
            //Update
            return state.map((item: any) =>
              item?.[propertyId] === action.payload?.[propertyId]
                ? action.payload
                : item
            );
          }
          //push
          return [...state, action.payload];
        } else {
          //add or update by key
          if (state.some((item: any) => item === action.payload)) {
            //Update
            return state.map((item: any) =>
              item === action.payload ? action.payload : item
            );
          }
          //push
          return [...state, action.payload];
        }
      },
      [SET_ITEM_PROPERTY]: () => {
        const propertyId =
          "propertyId" in action
            ? (action as ReduxActionArraySetItemProperty).propertyId
            : "";
        const idToUpdate =
          "idToUpdate" in action
            ? (action as ReduxActionArraySetItemProperty).idToUpdate
            : "";
        const propertyName =
          "propertyName" in action ? action.propertyName : "";
        return state.map((item: any) => {
          if (item?.[propertyId] === idToUpdate) {
            //update
            return {
              ...item,
              [propertyName]: action.payload,
            };
          }
          return item;
        });
      },
      [DELETE_ALL_ITEMS]: () => {
        const propertyId = "propertyId" in action ? action.propertyId : "";

        if (propertyId) {
          return state.filter(
            (item: any) => item?.[propertyId] !== action.payload
          );
        }
        return state.filter((item: any) => item !== action.payload);
      },
      [RESET]: () => defaultState,
    };
    if (actions[action.operation]) {
      return actions[action.operation]();
    } else {
      throw new Error(
        `Unknown operation ${action.operation} for reducer: ${action.type}`
      );
    }
  }
  return state;
};
