import { combineReducers } from "redux";
import {
  REDUX_IFRAME_FORM_ERRORS,
  REDUX_IFRAME_FORM_FILLED_FORMS,
  REDUX_IFRAME_FORM_NUMBER,
  REDUX_IFRAME_FORM_TIMER,
} from "../../constant";
import { createReducerArray } from "../../../../redux/tools/createReducer/createReducerArray";
import { createReducerNumber } from "../../../../redux/tools/createReducer/createReducerNumber";

export const iframeReducer = combineReducers({
  errors: createReducerArray<string>(REDUX_IFRAME_FORM_ERRORS, []),
  filledInputs: createReducerArray<string>(REDUX_IFRAME_FORM_FILLED_FORMS, []),
  moneyCzSlider: createReducerNumber(REDUX_IFRAME_FORM_NUMBER, 0),
  timer: createReducerNumber(REDUX_IFRAME_FORM_TIMER, null),
});
