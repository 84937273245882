export const ENDPOINT_SUBMIT_FORM = "/submit";

export const URL_FORM = "/formular";

export const IFRAME_TIMER_LAST_START = "IFRAME_TIMER_LAST_START";

export const REDUX_IFRAME_FORM_ERRORS = "REDUX_IFRAME_FORM_ERRORS";
export const REDUX_IFRAME_FORM_FILLED_FORMS = "REDUX_IFRAME_FORM_FILLED_FORMS";
export const REDUX_IFRAME_FORM_NUMBER = "REDUX_IFRAME_FORM_NUMBER";
export const REDUX_IFRAME_FORM_TIMER = "REDUX_IFRAME_FORM_TIMER";

export interface Ask {
  positive: boolean;
  ask: string;
  type: keyof FormDataType;
}

export interface FormDataSectionType {
  inputs: Array<Ask>;
}

interface FormDataSectionDataType {
  inputs: Array<Omit<Ask, "type">>;
}

export interface FormDataType {
  sales: FormDataSectionDataType;
  marketingSales: FormDataSectionDataType;
  marketing: FormDataSectionDataType;
  realization: FormDataSectionDataType;
  publicRelations: FormDataSectionDataType;
  vision: FormDataSectionDataType;
  preference: FormDataSectionDataType;
}

// copy of this is in public/static/iframe.html
const FORM_DATA: FormDataType = {
  sales: {
    inputs: [
      {
        positive: true,
        ask:
          "Máme precizního obchodníka či špičkový obchodní tým, který přináší stále více klientů a objednávek.",
      },
      {
        positive: false,
        ask: "Konkurenci vnímáme jako silného protivníka.",
      },
      {
        positive: true,
        ask:
          "O našich klientech víme úplně všechno, máme s nimi úžasný vztah a prodáváme jim s úspěchem opakovaně.",
      },
      {
        positive: false,
        ask: "U stávajících klientů nám klesají prodeje.",
      },
      {
        positive: false,
        ask:
          "Naši klienti více vnímají osobu obchodníka než náš tým jako společnost.",
      },
      {
        positive: true,
        ask:
          "Všichni v obchodním týmu soutěží a předhánějí se, kdo bude mít lepší výsledky.",
      },
      {
        positive: false,
        ask: "Někteří obchodníci mají rezervy ve výsledcích.",
      },
      {
        positive: false,
        ask: "Musíme podněcovat obchodníky, aby nosili více zisku.",
      },
      {
        positive: false,
        ask:
          "V naší branži je to hlavně o cenách. Více zákazníků má ten, kdo je levnější.",
      },
      {
        positive: true,
        ask:
          "Kdybychom měli kompetentnější obchodníky, rozhodně bychom měli větší zisk.",
      },
      {
        positive: false,
        ask: "Když je období dovolených, logicky klesá prodej.",
      },
    ],
  },
  marketingSales: {
    inputs: [
      {
        positive: true,
        ask:
          "Víme přesně, co naši klienti potřebují i chtějí. Také víme, čeho se obávají i co jim vadí.",
      },
      {
        positive: true,
        ask:
          "Neustále jsme v kontaktu s našimi klienty, abychom o nich věděli všechno, co potřebujeme.",
      },
      {
        positive: true,
        ask:
          "Známe skutečnou efektivitu a dopad našich marketingových kampaní.",
      },
      {
        positive: false,
        ask: "Někteří naši klienti vlastně ani nevědí, co chtějí.",
      },
      {
        positive: false,
        ask: "Se zákazníky je to těžké, pořád mění názory.",
      },
      {
        positive: true,
        ask: "O klientech toho víme tolik, že to ani nestíháme zpracovat.",
      },
      {
        positive: false,
        ask: "Průzkumy jsou zbytečné. Stejně si každý koupí to, co chce.",
      },
      {
        positive: true,
        ask: "Naši klienti se podílí na dění u nás ve firmě.",
      },
    ],
  },
  marketing: {
    inputs: [
      { positive: false, ask: "Na marketing nemáme peníze." },
      {
        positive: false,
        ask:
          "Nejdůležitější je mít kvalitní obchodní tým, ten přinese nejvíce peněz.",
      },
      { positive: false, ask: "Reklamy stejně nikdo nesleduje." },
      {
        positive: true,
        ask:
          "Na propagaci máme specialistu, který se této činnosti věnuje precizně a jeho výsledky jsou hmatatelné.",
      },
      {
        positive: true,
        ask:
          "Máme pod kontrolou všechny marketingové kampaně a jasně vidíme, kolik zisku a v jakém čase přinášejí.",
      },
      {
        positive: true,
        ask:
          "Při marketingových akcích máme precizně pod kontrolou, že díky nim skutečně vyděláváme a peníze na ně vynakládáme opravdu investičním způsobem.",
      },
    ],
  },
  realization: {
    inputs: [
      {
        positive: true,
        ask: "U nás platí základní pravidlo; co slíbíme, to splníme.",
      },
      {
        positive: true,
        ask:
          "Vždy, když klient obdrží náš produkt či službu je maximálně spokojen.",
      },
      {
        positive: true,
        ask: "Máme tak spokojené klienty, že každý nám přivede další dva nové.",
      },
      {
        positive: true,
        ask:
          "Odměny pracovníků za výsledky jsou u nás vázané na spokojenost klientů.",
      },
      {
        positive: true,
        ask: "S každým naším spokojeným klientem se ihned chlubíme.",
      },
      {
        positive: true,
        ask:
          "Děláme si pravidelnou kontrolu, jak jsou naši klienti s námi spokojení.",
      },
      {
        positive: true,
        ask: "Na péči o nespokojené klienty máme určenou pracovní pozici.",
      },
      {
        positive: false,
        ask: "V poslední době se nám nedaří stíhat množství zakázek.",
      },
    ],
  },
  publicRelations: {
    inputs: [
      {
        positive: false,
        ask: "Public relations se vyplatí jen bohatým firmám.",
      },
      { positive: false, ask: "Na sponzoring či charitu nemáme peníze." },
      {
        positive: true,
        ask:
          "Pro významné klienty pořádáme zvláštní akce, kde se o ně staráme.",
      },
      {
        positive: true,
        ask:
          "Účastníme se skutečně mnoha veletrhů a výstav jako vystavovatelé.",
      },
      {
        positive: true,
        ask: "Naše zákazníky sdružujeme do zájmových skupin.",
      },
      {
        positive: true,
        ask:
          "Ve firmě máme vyhrazenou pozici, která se stará o dobré jméno naší firmy v očích veřejnosti.",
      },
      {
        positive: true,
        ask:
          "Firemní časopis ve variantách pro zaměstnance i zákazníky je u nás samozřejmostí.",
      },
      {
        positive: true,
        ask:
          "Pravidelně pracujeme na tom, aby o nás věděla i široká veřejnost.",
      },
      {
        positive: false,
        ask:
          "Na odborná setkání našeho oboru (konference, kongresy apod.) jezdíme zřídka.",
      },
    ],
  },
  vision: {
    inputs: [
      {
        positive: false,
        ask: "Majitel je jediná osoba ve firmě, která o všem rozhoduje.",
      },
      {
        positive: false,
        ask:
          "V čase krize je zapotřebí snížit všechny náklady a zbavit se co možná nejvíce výdajů.",
      },
      {
        positive: true,
        ask: "Nejdůležitějším článkem ve firmě jsou pracovníci.",
      },
      {
        positive: false,
        ask: "Firmu jsem si založil/a jen proto, abych se měl/a lépe.",
      },
      {
        positive: false,
        ask: "Nejdůležitější věc je mít vysoký zisk. To je nadevše.",
      },
      {
        positive: false,
        ask:
          "Kdo by se v tom shonu ještě staral o to, co chtějí moji zaměstnanci?",
      },
      {
        positive: true,
        ask:
          "O mých přímých podřízených vím všechno z jejich soukromí, protože se o ně autenticky zajímám.",
      },
      {
        positive: false,
        ask: "Přátelství je dobrá věc, ale takové vztahy do firmy nepatří.",
      },
      { positive: true, ask: "Celou svoji firmu mám pod kontrolou." },
      {
        positive: false,
        ask: "V poslední době se nám nedaří plnit stanovené plány.",
      },
      { positive: false, ask: "Od doby krize to jde z kopce." },
    ],
  },
  preference: {
    inputs: [
      {
        positive: false,
        ask: "Už mi nezbývá na záliby a koníčky tolik času jako dříve.",
      },
      {
        positive: false,
        ask: "V práci trávím více času, než bych chtěl/a.",
      },
      {
        positive: false,
        ask: "Občas si dělám starosti o své zdraví.",
      },
      {
        positive: false,
        ask:
          "O práci se mi už i zdá, přitom by se mi mohly zdát příjemnější věci.",
      },
      {
        positive: true,
        ask: "Úspěch naší firmy mě motivuje k lepším a lepším výkonům.",
      },
      {
        positive: false,
        ask: "Chtěl/a bych trávit více času s rodinou.",
      },
      {
        positive: false,
        ask:
          "Už si ani nepamatuji, kdy jsem si přečetl/a nějakou knihu a mrzí mě to.",
      },
    ],
  },
};

function shuffleArray<T>(array: T[]): T[] {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

const ASK_PER_PAGE = 1;

export const FORM_DATA_RANDOM: Ask[][] = shuffleArray(
  Object.keys(FORM_DATA)
    .map((key) => {
      return FORM_DATA[key as keyof FormDataType].inputs.map(
        (item: Omit<Ask, "type">) => ({
          ...item,
          type: key,
        })
      );
    })
    .flat(1)
).reduce((all: any[], item: any, i) => {
  const ch = Math.floor(i / ASK_PER_PAGE);
  all[ch] = [].concat(all[ch] || [], item);
  return all;
}, []);
