import React from 'react'
import EC from "./EC.png"
import { Link } from "react-router-dom"
import "./Story.css"
export default function Home() {
    return (
        <div className="home">
            <a href="https://universityam.cz/">            <img className="ec" src={EC} alt="essential college" />
</a>
            <div className="section">

            <h1>Kondice vaší firmy</h1>
            <h4>Tato diagnostika vám výrazně pomůže <span>v budování prosperující firmy.</span> </h4>
            <p>Obsahuje <span>7 oblastí ve firemní struktuře</span>  a tématech představitele firmy.</p>
            <p>Dotazník se vyhodnocuje na základě odpovědí (A,B,C,D).</p>
            <p>Výsledek obdržíte <span>okamžitě a bezplatně.</span> </p>
            <p>Vyplnění trvá <span>maximálně 5 minut.</span></p>
            <a href="/formular/"><button>OTESTUJTE SVOJI FIRMU</button></a>
            <p>
                    Tento dotazník jsme vytvořili pro každého podnikatele, který si chce udělat <span>nezávislý audit firmy.</span></p>


            </div>

        </div>
    )
}
