import React, { createContext, useRef } from "react";
import styled, { createGlobalStyle } from "styled-components/macro";
import { GlobalStyleProps } from "../../../types/styles";
import { CssBaseline } from "@material-ui/core";
import { useHistoryLocation } from "../../../core/history";

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  html,
  body,
  #root {
    height: 100%;
    font-size: 17.5px;
  }

  body{
    background: ${(props) => props.theme.palette.background.default};
  }
  h3{
    font-weight: 600 !important;
    font-size: 1.714rem !important;
    letter-spacing: -1.1px;
  }
  h6{
    font-weight: 400 !important;
    font-size: 1.1428571428rem  !important;
  }
  strong {
    font-weight: 600;
  }
  .iframe-check-box svg{
    width: 35px;
    height: 35px;
  }
  .form-submit-button svg{
    width: 40px;
    height: 40px;
  }
  ${({ theme }: { theme: any }) => theme.breakpoints.down("sm")}{
    html,
    body,
    #root {
      height: 100%;
      font-size: 15.2px;
    }
    
    .form-submit-button svg{
      width: 35px;
      height: 35px;
      margin-right: 7px;
    }

    body{
      background: white;
    }
  }
`;

const AppConatiner = styled.div<{ $hideBg: boolean }>`
  max-width: ${({ $hideBg }) => ($hideBg ? "100%" : "1110px")};
  margin: 0 auto;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

const Root = styled.div`
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
`;

export const ScrollUpContext = createContext<{ scrollUp?: () => void }>({});

const FormsLayout: React.FC = ({ children }) => {
  const scrollElement = useRef<HTMLDivElement>(null);
  const scrollUp = () => {
    setTimeout(() => {
      //need to wait to avoid stop scrolling bug
      if (scrollElement.current) {
        scrollElement.current.scrollTo(0, 0);
      }
    }, 10);
  };
  const ref = useRef<HTMLDivElement>(null);

  const location = useHistoryLocation<{ hideBg: "true" | null }>();

  return (
    <Root ref={scrollElement}>
      <ScrollUpContext.Provider value={{ scrollUp }}>
        <AppConatiner ref={ref} $hideBg={location.search?.hideBg === "true"}>
          <CssBaseline />
          <GlobalStyle />
          {children}
        </AppConatiner>
      </ScrollUpContext.Provider>
    </Root>
  );
};

export default FormsLayout;
