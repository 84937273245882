import React from "react";
import { Router, Redirect, Route, Switch } from "react-router-dom";
import FormsIframeRoute from "../../modules/formsIframe/container/FormsIframeRoute";
import { URL_FORM } from "../../modules/formsIframe/constant";
import { historyOnlyForRouter } from "../history";
import Story from "./Story.jsx";

const Routes = () => (
  <Router history={historyOnlyForRouter}>
    <Switch>
      <FormsIframeRoute path={URL_FORM} />
      <Route exact path="/">{Story}</Route>
      <Route exact render={() => <Redirect to={URL_FORM} />} />
    </Switch>
  </Router>
);

export default Routes;
