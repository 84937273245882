import * as React from "react";
import { FC } from "react";
import { Flex } from "../../../../core/components/layout/Flex";
import styled from "styled-components/macro";
import { H3, Body1 } from "../../../../core/components/typography";
import { MailOutline } from "@material-ui/icons";

const MailOutlineStyled = styled(MailOutline)`
  height: 90px;
  width: 90px;
`;

export const IframeIframeSubmitted: FC = () => (
  <Flex
    justifyContent={"center"}
    fullWidth={true}
    marginTop={"xxl"}
    height={"100%"}
    flexDirection={"column"}
    marginBottom={"xxl"}
    paddingLeft={"xxl"}
    paddingRight={"xxl"}
  >
    <Flex fullWidth={true} marginTop={"large"} justifyContent={"center"}>
      <MailOutlineStyled />
    </Flex>
    <Flex fullWidth={true} marginTop={"large"} justifyContent={"center"}>
    <H3 align={"center"}>Výsledek jsme vám odeslali na email!</H3>
    </Flex>
    <Flex fullWidth={true} marginTop={"large"} justifyContent={"center"}>
    <Body1 align={"center"}>Tento dotazník vytvořen na <a href="https://universityam.cz/">University of Applied Management</a> a <a href="https://essentialcollege.cz/">essential college</a></Body1>
    </Flex>
  </Flex>
);
