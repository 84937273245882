import { API_ROOT } from "../constants";
import { actionSnackbarShow } from "../../layouts/store/actions/snackbar.actions";
import { AppDispatchType } from "../../../redux/store";

export interface RequestResponse {
  code?: number;
  data: any;
  ok: boolean;
  error: any;
}

export const post = async (
  url: string,
  body: any,
  throwErrorMessage: boolean = false
): Promise<RequestResponse> => {
  let code = undefined;
  let data = undefined;
  let ok = false;
  let error = null;
  await fetch(API_ROOT + url, {
    body: JSON.stringify(body),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      code = response.status;
      ok = response.ok;
      return response.json();
    })
    .then((responseJson) => {
      data = responseJson.data;
    })
    .catch((e) => {
      error = e;
      if (throwErrorMessage) {
        //TODO try find api message
        throw new Error(error);
      }
    });
  return {
    code,
    data,
    ok,
    error,
  };
};

export const put = async (url: string, body: any): Promise<RequestResponse> => {
  let code = undefined;
  let data = undefined;
  let ok = false;
  let error = null;
  await fetch(API_ROOT + url, {
    body: JSON.stringify(body),
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      code = response.status;
      ok = response.ok;
      return response.json();
    })
    .then((responseJson) => {
      data = responseJson.data;
    })
    .catch((e) => {
      error = e;
    });
  return {
    code,
    data,
    ok,
    error,
  };
};

export const patch = async (
  url: string,
  body: any
): Promise<RequestResponse> => {
  let code = undefined;
  let data = undefined;
  let ok = false;
  let error = null;
  await fetch(API_ROOT + url, {
    body: JSON.stringify(body),
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      code = response.status;
      ok = response.ok;
      return response.json();
    })
    .then((responseJson) => {
      data = responseJson.data;
    })
    .catch((e) => {
      error = e;
    });
  return {
    code,
    data,
    ok,
    error,
  };
};

export const del = async (
  url: string,
  body?: any
): Promise<RequestResponse> => {
  let code = undefined;
  let data = undefined;
  let ok = false;
  let error = null;
  await fetch(API_ROOT + url, {
    body: body && JSON.stringify(body),
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      code = response.status;
      ok = response.ok;
      return response.json();
    })
    .then((responseJson) => {
      data = responseJson.data;
    })
    .catch((e) => {
      error = e;
    });
  return {
    code,
    data,
    ok,
    error,
  };
};

export const get = async (url: string): Promise<RequestResponse> => {
  let code = undefined;
  let data = undefined;
  let ok = false;
  let error = null;
  await fetch(API_ROOT + url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      code = response.status;
      ok = response.ok;
      return response.json();
    })
    .then((responseJson) => {
      data = responseJson.data;
    })
    .catch((e) => {
      error = e;
    });
  return {
    code,
    data,
    ok,
    error,
  };
};

export const basicHandleError = (res: RequestResponse) => (
  dispatch: AppDispatchType
) => {
  switch (res.code) {
    case 400:
      dispatch(
        actionSnackbarShow({
          translateId: "Interní chyba serveru! Kontaktujte podporu.",
          variant: "error",
        })
      );
      break;
    default:
    case 500:
      dispatch(
        actionSnackbarShow({
          message: "Interní chyba serveru! Kontaktujte podporu.",
          variant: "error",
        })
      );
      break;
  }
};
