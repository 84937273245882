import { PropTypes, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { SystemMarginType } from "../../theme/Spacing";
import styled from "styled-components";
import { Variant } from "@material-ui/core/styles/createTypography";

interface BaseTextComponentProps {
  component?: React.ElementType;
  variant: Variant;
  align?: PropTypes.Alignment;
  marginTop?: SystemMarginType;
  marginBottom?: SystemMarginType;
  className?: string;
}

interface StyledTypographyProps {
  $marginTop?: SystemMarginType;
  $marginBottom?: SystemMarginType;
  component: any;
}

const StyledTypography = styled(Typography)<StyledTypographyProps>`
  ${({ $marginTop, theme }) =>
    $marginTop && `margin-top: ${theme.margin($marginTop)}px;`}
  ${({ $marginBottom, theme }) =>
    $marginBottom && `margin-bottom: ${theme.spacing($marginBottom)}px;`}
`;

const BaseTextComponent: FC<BaseTextComponentProps> = ({
  align,
  component,
  marginBottom,
  marginTop,
  variant,
  className,
  children,
}) => {
  return (
    <StyledTypography
      $marginBottom={marginBottom}
      $marginTop={marginTop}
      component={component}
      variant={variant}
      align={align}
      className={className}
      gutterBottom
    >
      {children}
    </StyledTypography>
  );
};

interface BaseTypographyProps {
  align?: PropTypes.Alignment;
  marginTop?: SystemMarginType;
  marginBottom?: SystemMarginType;
}

export const H1: FC<BaseTypographyProps> = (props) => (
  <BaseTextComponent {...props} component={"h1"} variant={"h1"} />
);

export const H2: FC<BaseTypographyProps> = (props) => (
  <BaseTextComponent {...props} component={"h2"} variant={"h2"} />
);

export const H3: FC<BaseTypographyProps> = (props) => (
  <BaseTextComponent {...props} component={"h3"} variant={"h3"} />
);

export const H4: FC<BaseTypographyProps> = (props) => (
  <BaseTextComponent {...props} component={"h4"} variant={"h4"} />
);

export const H5: FC<BaseTypographyProps> = (props) => (
  <BaseTextComponent {...props} component={"h5"} variant={"h5"} />
);

export const H6: FC<BaseTypographyProps> = (props) => (
  <BaseTextComponent {...props} component={"h6"} variant={"h6"} />
);

export const Body1: FC<BaseTypographyProps> = (props) => (
  <BaseTextComponent {...props} component={"p"} variant={"body1"} />
);

export const Body2: FC<BaseTypographyProps> = (props) => (
  <BaseTextComponent {...props} component={"p"} variant={"body2"} />
);

export const Caption: FC<BaseTypographyProps> = (props) => (
  <BaseTextComponent {...props} component={"p"} variant={"caption"} />
);

export const Overline: FC<BaseTypographyProps> = (props) => (
  <BaseTextComponent {...props} component={"p"} variant={"overline"} />
);

export const Subtitle1: FC<BaseTypographyProps> = (props) => (
  <BaseTextComponent {...props} component={"p"} variant={"subtitle1"} />
);

export const Subtitle2: FC<BaseTypographyProps> = (props) => (
  <BaseTextComponent {...props} component={"p"} variant={"subtitle2"} />
);
