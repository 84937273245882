import React, { FC } from "react";
import { Flex } from "../../../../core/components/layout/Flex";
import FormPercentages from "./FormPercentages";
import { FormDataType } from "../../constant";
import { CurrentPage, FormAnswers, SubmittedStatus } from "../../container/FormsIframe";

interface Props {
  currentPage: CurrentPage;
  formValues: FormAnswers;
  submitted: SubmittedStatus;
}

const IframeHeader: FC<Props> = ({ currentPage, submitted, formValues }) => (
  <Flex
    fullWidth={true}
    flexDirection={"column"}
    marginBottom={"small"}
    marginTop={"xxl"}
  >
    {submitted === null && (
      <FormPercentages currentPage={currentPage} formValues={formValues} />
    )}
  </Flex>
);

export default React.memo(IframeHeader);
