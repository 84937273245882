import { createMuiTheme } from "@material-ui/core/styles";
import overrides from "./overrides";
import breakpoints from "../../../core/theme/breakpoints";
import props from "../../../core/theme/props";
import typography from "../../../core/theme/typography";
import shadows from "../../../core/theme/shadows";
import { getSystemMarginValue } from "../../../core/theme/Spacing";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";

const getThemeConfig = (
  primary: string,
  secondary: string
): { palette: PaletteOptions } => ({
  palette: {
    type: "light",
    primary: {
      main: primary,
      contrastText: "#FFF",
    },
    secondary: {
      main: secondary,
      contrastText: "#FFF",
    },
    background: {
      default: "#e3e3e3",
      paper: "#FFF",
    },
    text: {
      primary: "#000",
    },
    success: {
      main: "#6ab23a",
    },
  },
});

const createFormTheme = (primary: string, secondary: string) => {
  const themeConfig = getThemeConfig(primary, secondary);

  return createMuiTheme(
    {
      spacing: 4,
      breakpoints: breakpoints,
      overrides: overrides(themeConfig.palette),
      props: props,
      typography: typography,
      shadows: shadows,
      palette: themeConfig.palette,
    },
    {
      margin: getSystemMarginValue,
    }
  );
};

export default createFormTheme;
