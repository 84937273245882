export type SystemMarginType =
  | "auto"
  | "mini"
  | "small"
  | "medium"
  | "large"
  | "xl"
  | "xxl";

export const systemMargin: Record<SystemMarginType, number | "auto"> = {
  mini: 0.5,
  auto: "auto",
  small: 1,
  medium: 2,
  large: 3,
  xl: 5,
  xxl: 7,
};
export const getSystemMarginValue = (margin: SystemMarginType) =>
  systemMargin[margin];
