import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk, { ThunkDispatch } from "redux-thunk";
import { themeReducer } from "../../core/theme/store/reducer/theme.reducer";
import { lockableReducer } from "../../core/layouts/store/reducer/lockable.reducer";
import { layoutReducer } from "../../core/layouts/store/reducer/layout.reducer";
import { iframeReducer } from "../../modules/formsIframe/store/store/iframe.reducer";

const composeEnhancers = composeWithDevTools({});

const rootReducer = combineReducers({
  themeReducer,
  lockable: lockableReducer,
  layout: layoutReducer,
  iframe: iframeReducer,
});
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export type AppDispatchType = ThunkDispatch<any, any, any>;

export default store;

type RootReducerType = typeof rootReducer;
export type AppStateType = ReturnType<RootReducerType>;
