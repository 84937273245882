import {
  actionReducerArrayDelete,
  actionReducerArrayPush,
  actionReducerArraySetItemProperty,
  ReduxActionArrayPush,
} from "../../../../redux/tools/createReducer/createReducerArray";
import { REDUX_LAYOUT_SNACKBARS } from "../../../constants";
import { SnackbarMessage } from "../../../components/layout/snackbar/type";
import { SnackbarKey } from "notistack";
import { Optional } from "../../../type/helpTypes";

export const actionSnackbarShow = (
  notification: Optional<SnackbarMessage, "key" | "dismissed">
): ReduxActionArrayPush => {
  return actionReducerArrayPush(
    REDUX_LAYOUT_SNACKBARS,
    {
      ...notification,
      dismissed: false,
      key:
        notification.key ||
        `${String(new Date().getTime())}${Math.random().toString()}`,
    },
    "key"
  );
};

export const actionSnackbarHide = (key: SnackbarKey) => {
  return actionReducerArraySetItemProperty(
    REDUX_LAYOUT_SNACKBARS,
    key,
    true,
    "dismissed",
    "key"
  );
};

export const snackbarRemove = (key: SnackbarKey) =>
  actionReducerArrayDelete(REDUX_LAYOUT_SNACKBARS, key, "key");
