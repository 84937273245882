import * as React from "react";
import { FC } from "react";
import { IframeFiledProps } from "../IframeInputsList";
import Radio from "@material-ui/core/Radio";
import { FormControl, FormControlLabel, RadioGroup } from "@material-ui/core";
import { Body2 } from "../../../../core/components/typography";
import { Flex } from "../../../../core/components/layout/Flex";
import styled from "styled-components/macro";

const Body2Styled = styled(Body2)`
  font-weight: 500;
`;

export const IframeSelect: FC<IframeFiledProps> = ({
  onChange,
  ask,
  value,
  index,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange((event.target as HTMLInputElement).value);
  };

  return (
    <Flex fullWidth={true} marginBottom={"xxl"}>
      <FormControl component="fieldset">
        <Body2Styled>{ask.ask}</Body2Styled>
        <RadioGroup
          name={String(index)}
          value={value || ""}
          onChange={handleChange}
        >
          <FormControlLabel
            value="a"
            control={<Radio />}
            label={"Rozhodně souhlasím"}
          />
          <FormControlLabel
            value="b"
            control={<Radio />}
            label="Spíše souhlasím"
          />
          <FormControlLabel value="c" control={<Radio />} label="Nevím" />
          <FormControlLabel
            value="d"
            control={<Radio />}
            label="Spíše nesouhlasím"
          />
          <FormControlLabel
            value="e"
            control={<Radio />}
            label="Rozhodně nesouhlasím"
          />
        </RadioGroup>
      </FormControl>
    </Flex>
  );
};
