import React from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import DateFnsUtils from "@date-io/date-fns";

import { ThemeProvider } from "styled-components/macro";
import { create, Plugin } from "jss";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
  jssPreset,
} from "@material-ui/core/styles";

import createTheme from "./core/theme";
import Routes from "./core/routes/Routes";
import { AppStateType } from "./redux/store";
import { SnackbarProvider } from "notistack";
import SnackbarShower from "./core/components/layout/snackbar/SnackbarShower";

const jss = create({
  ...jssPreset(),
  plugins: [...jssPreset().plugins] as readonly Plugin[],
  insertionPoint: document.getElementById("jss-insertion-point")!,
});

function App() {
  const theme = useSelector((state: AppStateType) => state.themeReducer);

  return (
    <React.Fragment>
      <Helmet
        titleTemplate="Kondice firmy"
        defaultTitle="Kondice firmy"
      />
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiThemeProvider theme={createTheme(theme.currentTheme)}>
            <ThemeProvider theme={createTheme(theme.currentTheme)}>
              <SnackbarProvider>
                <SnackbarShower />
                <Routes />
              </SnackbarProvider>
            </ThemeProvider>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </React.Fragment>
  );
}

export default App;
