import * as React from "react";
import { FC, useState } from "react";
import { IframeInputsList } from "../components/IframeInputsList";
import { Paper } from "@material-ui/core";
import IframeHeader from "../components/header/IframeHeader";
import styled from "styled-components";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { ThemeProvider } from "styled-components/macro";
import createFormTheme from "../theme";
import { Helmet } from "react-helmet";
import { IframeSubmitButton } from "../components/IframeSubmitButton";
import { FORM_DATA_RANDOM } from "../constant";
import {
  ContactInfo,
  IframeContactInfo,
} from "../components/inputs/IframeContactInfo";
import { IframeIframeSubmitted } from "../components/inputs/IframeSubmitted";

const Root = styled(Paper)`
  padding: ${({ theme }) => theme.spacing(4)}px;
  margin: ${({ theme }) => theme.spacing(15, 4, 15, 4)};
  border-color: ${({ theme }) => theme.palette.primary.main};
  border-width: ${({ theme }) => 0}px;
  width: 100%;
  max-width: 100%;

  ${({ theme }) => theme.breakpoints.down("md")} {
    max-width: ${() => "calc(100% - 32px)"};
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin: 0;
    padding: 0px;
    max-width: 100%;
  }
`;

export type SubmittedStatus = "filled" | null | "submitted";

export type Value = "a" | "b" | "c" | "d" | "e";

export type FormAnswerPage = Value[];
export type FormAnswers = FormAnswerPage[];

export type CurrentPage = number;

const FormsIframe: FC = () => {
  const keys = Array(FORM_DATA_RANDOM.length)
    .fill("")
    .map((item, index) => index);
  const [submitted, setSubmitted] = useState<SubmittedStatus>(null);
  const [currentPage, setCurrentPage] = useState<CurrentPage>(0);
  const [formValues, setFormValues] = useState<FormAnswers>(keys.map(() => []));
  const [contactInfo, setContactInfo] = useState<ContactInfo>({
    phone: "",
    email: "",
    firstName: "",
    company: "",
    lastName: "",
  });

  const handleChange = (selection: number) => (name: number, value: Value) => {
    formValues[selection][name] = value;
    setFormValues([...formValues]);
  };

  return (
    <MuiThemeProvider theme={createFormTheme("#00bcd4", "#64b5f6")}>
      <ThemeProvider theme={createFormTheme("#00bcd4", "#64b5f6")}>
        <Helmet titleTemplate="%s" defaultTitle="">
          <title itemProp="name">Kondice firmy</title>
          <meta
            name="description"
            content={"Zjistěte silné a slabé stránky vaší společnosti"}
          />
        </Helmet>
        <Root id={"test"}>
          <IframeHeader
            submitted={submitted}
            currentPage={currentPage}
            formValues={formValues}
          />
          {submitted === "submitted" ? (
            <IframeIframeSubmitted />
          ) : submitted === "filled" ? (
            <IframeContactInfo
              contact={contactInfo}
              setSubmitted={setSubmitted}
              submitted={submitted}
              onChangeContact={(name) => (value) => {
                setContactInfo({ ...contactInfo, [name]: value });
              }}
            />
          ) : (
            <IframeInputsList
              key={currentPage}
              onChange={handleChange(currentPage)}
              formValues={formValues[currentPage]}
              formFields={FORM_DATA_RANDOM[currentPage]}
            />
          )}
          <IframeSubmitButton
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            formValues={formValues}
            pages={keys}
            contact={contactInfo}
            setSubmitted={setSubmitted}
            submitted={submitted}
          />
        </Root>
      </ThemeProvider>
    </MuiThemeProvider>
  );
};
export default FormsIframe;
