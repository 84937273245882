import { Flex } from "../../../core/components/layout/Flex";
import {
  Button,
  CircularProgress,
  Hidden,
  makeStyles,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
import { Send } from "@material-ui/icons";
import * as React from "react";
import { FC, useContext, useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { ScrollUpContext } from "../layout/FormsLayout";
import {
  CurrentPage,
  FormAnswers,
  SubmittedStatus,
} from "../container/FormsIframe";
import { ContactInfo } from "./inputs/IframeContactInfo";
import { getResult } from "../utils/getResult";
import { actionSnackbarShow } from "../../../core/layouts/store/actions/snackbar.actions";
import { postFormContentForm } from "../utils/postIframeContentForm";
import { basicHandleError } from "../../../core/api/utils/fetchApi";
import { isValidEmail } from "../../../core/utils/isValidEmail";
import { FORM_DATA_RANDOM } from "../constant";

interface IframeSubmitButtonProps {
  currentPage: CurrentPage;
  setCurrentPage: (number: CurrentPage) => void;
  pages: number[];
  formValues: FormAnswers;
  setSubmitted: (value: SubmittedStatus) => void;
  submitted: SubmittedStatus;
  contact: ContactInfo;
}

const FlexRoot = styled(Flex)`
  margin-top: 20px;

  ${({ theme }) => theme.breakpoints.down("xs")} {
    margin-top: 20px;
  }
`;

const ButtonBack = styled(Button)`
  font-size: 17px;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    position: absolute;
    left: 40px;
    margin-top: 0;
  }
  ${({ theme }) => theme.breakpoints.down("xs")} {
    margin-top: ${({ theme }) => theme.spacing(theme.margin("xxl"))}px;
    position: unset;
  }
`;

const ButtonSubmit = styled(Button)`
  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 18px;
    padding: 12px 16px;
  }
`;

const SendStyled = styled(Send)`
  margin-left: ${({ theme }) => theme.spacing(theme.margin("large"))}px;
`;

const useStyles = makeStyles((theme) => ({
  buttonRoot: {
    position: "relative",
  },
  buttonLoading: {
    position: "absolute",
  },
  disabled: {
    background: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.primary.contrastText} !important`,
    opacity: 0.4,
  },
}));

const useIsPageFilled = ({
  formValues,
  currentPage,
}: {
  formValues: FormAnswers;
  currentPage: CurrentPage;
}) => {
  return FORM_DATA_RANDOM[currentPage].some((input, index) => {
    return formValues[currentPage][index] === undefined;
  });
};

export const IframeSubmitButton: FC<IframeSubmitButtonProps> = ({
  currentPage,
  setCurrentPage,
  setSubmitted,
  submitted,
  contact,
  formValues,
}) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const filled = useIsPageFilled({ formValues, currentPage });

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const { scrollUp } = useContext(ScrollUpContext);
  const pageIndex = currentPage;
  const dispatch = useDispatch();
  const emailIsValid = isValidEmail(contact.email);

  const onSubmit = () => {
    setIsLoading(true);
    postFormContentForm(
      "http://kondice.rybasmrdiodhlavy.cz/static/iframe.html?data=" +
        encodeURI(JSON.stringify({ ...getResult(formValues), ...contact })),
      contact
    )
      .then((value) => {
        setIsLoading(false);
        if (value.ok) {
          setSubmitted("submitted");
          return;
        }
        return dispatch(basicHandleError(value));
      })
      .catch(() => {
        dispatch(
          actionSnackbarShow({
            variant: "error",
            message: "Chyba při generování pdf",
          })
        );
        setIsLoading(false);
      });
  };

  return (
    <FlexRoot
      justifyContent={"center"}
      marginLeft={"large"}
      marginRight={"large"}
      marginBottom={"large"}
      position={"relative"}
      flexDirection={"row"}
      flexWrap={smDown ? "wrap" : undefined}
    >
      {submitted === null && currentPage !== 0 ? (
        <Flex
          alignItems={"center"}
          marginRight={"xxl"}
          justifyContent={smDown ? undefined : "center"}
        >

        </Flex>
      ) : (
        <Flex />
      )}
      <Flex flexDirection={"column"} alignItems={"center"}>
        {(submitted === "filled" || submitted === null) && (
          <Flex flexDirection={"row"} alignItems={"center"}>
            <ButtonSubmit
              className={"form-submit-button"}
              variant={"contained"}
              color={"primary"}
              onClick={() => {
                if (pageIndex === undefined) {
                  return;
                }

                if (submitted === "filled") {
                  onSubmit();
                } else if (submitted === null) {
                  if (pageIndex + 1 === FORM_DATA_RANDOM.length) {
                    setSubmitted("filled");
                  } else {
                    setCurrentPage(pageIndex + 1);
                    if (scrollUp) {
                      scrollUp();
                    }
                  }
                }
              }}
              classes={{
                disabled: classes.disabled,
                root: classes.buttonRoot,
              }}
              disabled={
                (submitted === null
                  ? filled
                  : !(
                      contact.email &&
                      contact.phone &&
                      contact.firstName &&
                      contact.lastName &&
                      emailIsValid
                    )) || isLoading
              }
            >
              {isLoading && (
                <CircularProgress
                  className={classes.buttonLoading}
                  color={"secondary"}
                />
              )}
              {submitted === null ? (
                "Další ->"
              ) : (
                <>
                  Odeslat <SendStyled />
                </>
              )}
            </ButtonSubmit>
          </Flex>
        )}
      </Flex>
      <Hidden smDown={true}>
        <Flex />
      </Hidden>
    </FlexRoot>
  );
};
