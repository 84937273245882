import * as React from "react";
import { FC } from "react";
import { Route, RouteProps } from "react-router";
import FormsLayout from "../layout/FormsLayout";
import FormsIframe from "./FormsIframe";

const FormsIframeRoute: FC<Omit<RouteProps, "render" | "component">> = (
  props
) => {
  return (
    <Route
      {...props}
      render={(props) => (
        <FormsLayout>
          <FormsIframe />
        </FormsLayout>
      )}
    />
  );
};

export default FormsIframeRoute;
