// Theme
export const THEME_SET = "THEME_SET";
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

// redux
export const REDUX_LOCKABLE_LOCKED = "REDUX_LOCKABLE_LOCKED";
export const REDUX_LAYOUT_SNACKBARS = "REDUX_LAYOUT_SNACKBARS";
