import * as React from "react";
import { FC } from "react";
import { Flex } from "../../../core/components/layout/Flex";
import { Grid } from "@material-ui/core";
import { IframeSelect } from "./inputs/IframeSelect";
import styled from "styled-components";
import { Ask } from "../constant";
import { FormAnswerPage, Value } from "../container/FormsIframe";

const Root = styled.div`
  margin: 0 ${({ theme }) => theme.spacing(27)}px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    margin: 0 ${({ theme }) => theme.spacing(5)}px;
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin: 0;
    padding: 0;
  }
  #terms-and-condition {
    td,
    table,
    tr,
    tbody {
      border: 1px solid #d5d4d4;
      border-spacing: 0px;
    }
  }
`;

export interface IframeFiledProps {
  onChange: (newValue: any) => void;
  ask: Ask;
  value: any;
  index: number;
}

interface IframeInputsListProps {
  onChange: (name: number, value: Value) => void;
  formFields: Ask[];
  formValues: FormAnswerPage;
}

export const IframeInputsList: FC<IframeInputsListProps> = ({
  formValues,
  formFields,
  onChange,
}) => {
  const handleChange = (index: number) => (value: any) => {
    onChange(index, value);
  };
  return (
    <Root>
      <Flex flexWrap={"wrap"} marginLeft={"large"} marginRight={"large"}>
        <Grid container justify={"center"}>
          {formFields.map((item, index) => (
            <IframeSelect
              key={index}
              index={index}
              value={formValues[index]}
              ask={item}
              onChange={handleChange(index)}
            />
          ))}
        </Grid>
      </Flex>
    </Root>
  );
};
